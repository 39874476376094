@tailwind base;
@tailwind components;
@tailwind utilities;
@import './variables.css';

@font-face {
  font-family: 'Effra';
  src: url('/fonts/Effra Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: 'Effra', sans-serif;
}

.bg-gradient-primary {
  background: linear-gradient(103.14deg, #0156fb 1.75%, #063fbe 87.58%);
}

.bg-gradient-glance {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.1) 8.26%,
      rgba(234, 243, 255, 0.8) 30.87%,
      #ffffff 51.84%,
      rgba(233, 243, 255, 0.25) 69.73%,
      #ffffff 100%);
}

.bg-gradient-secondary {
  background: linear-gradient(180deg, #d9eafc 0%, #ffffff 100%);
}

.bg-lightblue {
  background: #69ffff1a;
}

.bg-empower-card {
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="%23667085"></path></svg>');
  background-repeat: no-repeat;
  background-position: 95% center;
}

[lang='ar'] select {
  background-position: 5% center;
}

input {
  @apply h-fit outline-none;
}

.bg-gradient-secondary {
  background: linear-gradient(180deg, #d9eafc 0%, #ffffff 100%);
}

.bg-lightblue {
  background: #69ffff1a;
}

.bg-get-in-touch-background {
  background: linear-gradient(180deg, #eaf4ff 0%, #ffffff 100%);
}

@media (min-width: 1024px) {
  .primary-box-shadow {
    box-shadow: 0px 2px 24px 0px #08083426;
  }
}

.executive-card {
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
}

.testimonial-card {
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  margin-bottom: 20px;
}

.plyr {
  height: 100% !important;
}

.plyr video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.input-form {
  @apply w-full py-4 px-5 bg-[#FDFDFD] rounded-lg text-[#6D6B83] border border-[#2353F51A] outline-none;
}

.error-input-message {
  @apply text-xs text-[#B42318];
}

.phone-border {
  border: 1px solid #2353f51a !important;
  border-radius: 8px;
}

.input-error-border {
  border: 1px solid #fda29b !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #6d6b83;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fdfdfd;
}

.terms-of-Service {
  border-bottom: 1px solid #6d6b83 !important;
}

.section-title {
  @apply text-3xl md:text-6xl leading-9 md:leading-[67px];
}

.prices-header_boder_white {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.border-gray {
  border: 1px solid #e3e8ef;
}

.border-gray-light {
  border: 1px solid #cdd5df;
}

.btn {
  @apply w-fit py-[12px] rounded-[100px];
}

.btn-primary {
  @apply bg-[#2353F5] text-white hover:bg-[#2354f59a] transition-colors duration-300;
}

.btn-outline-primary {
  border: 1px solid #2353f5;
  @apply text-[#2353f5] bg-[#fff] hover:bg-[#2353f5] hover:text-[#fff] transition-colors duration-300;
}

.border-level {
  border: 1px solid #00000062;
}

.rounded-l-subscribe {
  @apply !rounded-r-[8px];
}

.rounded-r-subscribe {
  @apply !rounded-l-[8px];
}

.footer_social-icon svg path {
  @apply fill-white;
}

.hr-toolkit-icons {
  @apply cursor-pointer;
}

.hr-toolkit-icons:hover svg path {
  @apply hover:text-primary fill-primary;
}

[lang='ar'] .rounded-r-subscribe {
  border-radius: 0 8px 8px 0 !important;
}

[lang='ar'] .rounded-l-subscribe {
  border-radius: 8px 0 0 8px !important;
}

.hr-toolkit-icons {
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hr-toolkit-icons svg path {
  @apply fill-[--color-base-colors-grey-600];
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
  /* For Firefox */
}

.flex-phone-and-large {
  @apply flex flex-col md:flex-row;
}

.demo-header {
  background-image: linear-gradient(#232181, #2353f5 59%, #6a8cff 95%);
  @apply pb-2 text-white;
}

.new-demo-header {
  background-image: linear-gradient(180deg, #2353f5 0%, #6a8cff 100%);
  @apply pb-2 text-white px-4;
}

.demo-content {
  @apply pt-1 md:pt-2 flex flex-col gap-12;
}

.new-demo-content {
  @apply pt-5 md:pt-[84px] flex flex-col justify-between;
}

.border-gray {
  border: 1px solid #e3e8ef;
}

[lang='ar'] .custom-text-align-right {
  text-align: right !important;
}

[lang='ar'] .custom-text-align-center {
  text-align: center !important;
}

[lang='ar'] .custom-text-align-left {
  text-align: start !important;
}

.btn-gradient {
  color: #fff;
  background-image: linear-gradient(to right, #2353f5, #3d377d);
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (min-width: 100%) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.bg-footer-primary {
  background: linear-gradient(180deg, #0156fa 0%, #063fbe 100%);
}

.grid-item {
  float: left;
  width: 100%;
  height: 100%;
}

.bg-solutions-card {
  box-shadow: 0px 24px 64px 0px #0808340d;
}

.bg-solutions-section {
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(225, 245, 245, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.bg-success-story-section {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.1) 8.26%,
      rgba(234, 243, 255, 0.8) 30.87%,
      #ffffff 51.84%,
      rgba(233, 243, 255, 0.25) 69.73%,
      #ffffff 100%);
}

.bg-valued-customers-card {
  background: linear-gradient(180deg, #eaf4ff 0%, #ffffff 100%);
  border: 1px solid #deeaff;
}

.bg-industry-badge {
  background: #deeaff;
}

details>summary::-webkit-details-marker,
details>summary::marker,
summary::-webkit-details-marker,
summary::marker {
  display: none;
}

/* Remove default button appearance in Safari */
.accordion-button {
  appearance: none;
  -webkit-appearance: none;
}

/* Ensure custom arrow visibility */
.accordion-button::after {
  content: '';
  /* Your existing arrow styling */
}

input[type='checkbox'] {
  filter: none !important;
}

/* Parent class for better specificity */
.allow-default-styling a {
  color: -webkit-link;
  /* Chrome's default link color */
  text-decoration: underline;
  cursor: pointer;
}

/* .allow-default-styling ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
} */

.allow-default-styling ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

/* First level nested bullets */
.allow-default-styling ul ul {
  list-style-type: circle;
  margin-block-start: 0;
  margin-block-end: 0;
}

/* Second level nested bullets */
.allow-default-styling ul ul ul {
  list-style-type: square;
  margin-block-start: 0;
  margin-block-end: 0;
}

.allow-default-styling ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

/* Target selector example */
.allow-default-styling :target {
  outline: 2px solid #0969da;
  background-color: #f6f8fa;
}

.allow-default-styling input[type='checkbox'][class*="active"] {
  filter: none !important;
  position: relative;
  background-color: var(--color-base-colors-blue-600);
}

.allow-default-styling input[type='checkbox'][class*="active"]::after {
  filter: none !important;
  position: absolute;
  background-color: var(--color-base-colors-white-1000);
}

/* cookies message start */
#hs-eu-cookie-confirmation {
  min-width: 100%;
  bottom: 0 !important;
  top: unset !important;
  border-radius: unset;
  display: flex !important;
  justify-content: center !important;
}

#hs-eu-cookie-confirmation #hs-eu-cookie-confirmation-inner {
  flex-direction: row !important;
  padding: 12px !important;
  align-items: center !important;
}

#hs-eu-cookie-confirmation #hs-eu-confirmation-button {
  min-width: unset !important;
  height: unset !important;
  padding: 6px 50px !important;
}

@media screen and (max-width: 767px) {
  #hs-eu-cookie-confirmation {
    position: fixed !important;
    top: 80 !important;
  }

  #hs-eu-cookie-confirmation #hs-eu-cookie-confirmation-inner {
    flex-direction: column !important;
  }
}

/* cookies message end */

/* careers page not found  start */

.home-header {
  background-image: linear-gradient(#0e0949, #152181);
  position: relative;
}

/* careers page not found  start */